import { CHABOT_CLASSNAME } from "../constants/stockholm-chat-card";

export const selectChatbotFromDOM = (): HTMLElement => {
  return document.querySelector(`.${CHABOT_CLASSNAME}`) as HTMLElement;
};

export const checkIfChatAllowedFromAce = (): boolean | undefined => {
  const chatInfo = window.CallGuide?.api?.chat?.isChatAllowed();
  return chatInfo?.allowed || chatInfo?.active;
};
