import React from "react";
import { B2xChatLoader } from "@telia/b2x-chat-loader/react-esm";

import { ChatType } from "../../enums/chat-type";
import { useCookieConsent } from "../../hooks/use-cookie-consent/use-cookie-consent";
import { useUserData } from "../../hooks/use-user-data/use-user-data";
import { UserData } from "../../providers/user-data/UserDataContext";
import { isBetweenTime, isWeekend } from "../../utils/time-utils";

const selectDedicatedChat = (userData: UserData | null) => userData?.corpContact?.dedicatedChat;

const Chat = () => {
  const dedicatedChat = useUserData(selectDedicatedChat);
  const { hasConsent } = useCookieConsent();

  const checkIsChatEnabled = () => {
    const isRegionOfStockholm = dedicatedChat?.chatType === ChatType.LARGE_LIVE_CHAT;
    const isValidWorkHours = isWeekend()
      ? isBetweenTime(dedicatedChat?.weekendHourStartChat, dedicatedChat?.weekendHourEndChat, true)
      : isBetweenTime(dedicatedChat?.workingHourStartChat, dedicatedChat?.workingHourEndChat);

    if (isRegionOfStockholm && isValidWorkHours) {
      return true;
    }

    return false;
  };

  return hasConsent ? <B2xChatLoader isChatEnabled={checkIsChatEnabled()} type="b2x-chat-loader-b2b-dedicated-stockholm" /> : null;
};

export default Chat;
